import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MobileHeader = ({ toggle, DATA }) => {
    const { t, i18n } = useTranslation(); // Import useTranslation hook

    return (
        <div className={`fixed w-full top-0 left-0 mt-[4.5rem] block lg:hidden ${toggle ? 'translate-x-[0%]' : '-translate-x-[100%]'} transition-all duration-500 overflow-hidden h-[calc(100vh+4.5rem)]`}>
            <div className='h-full w-full bg-white'>
                <nav className={`top-0 left-0 h-screen overflow-auto w-full bg-redloryColor -z-10 pt-24 px-5 transform transition-all duration-500 ${toggle ? 'opacity-100' : 'opacity-0'}`}>
                    <ul className='w-full z-50 relative'>
                        {
                            DATA.map((item, index) => (
                                <li key={index} className='w-full group'>
                                    <div className='flex items-center h-16'>
                                        <Link className='!inline-block w-full' to={item.link} onClick={(e) => item.link ? '' : e.preventDefault()} >
                                            <motion.h6 className={'w-full text-black text-xl'}>
                                                {t(item.title[i18n.language])}
                                            </motion.h6>
                                        </Link>
                                        <div className='w-full'>
                                            {item.dropDown && (<div className='w-full block '></div>)}
                                        </div>
                                    </div>
                                    {
                                        item.dropDown &&
                                        <ul className='h-0 opacity-0 hidden group-hover:block group-hover:opacity-100 group-hover:h-auto transition-all duration-500 pl-[10%]'>
                                            {
                                                item.dropDown.map((subItem, subIndex) => (
                                                    <li key={subIndex} className='group/innerDropDown py-1'>
                                                        <div className='flex items-center'>
                                                            <Link to={subItem.link}>
                                                                <motion.h6 className={'text-nowrap text-black text-base'}>
                                                                    {t(subItem.title[i18n.language])}
                                                                </motion.h6>
                                                            </Link>
                                                        </div>
                                                        {
                                                            subItem.dropDown &&
                                                            <ul className='h-0 hidden group-hover/innerDropDown:block group-hover/innerDropDown:h-auto overflow-hidden transition-all duration-500 opacity-0 group-hover/innerDropDown:opacity-100 pl-[20%]'>
                                                                {
                                                                    subItem.dropDown.map((innerSubItem, innerSubIndex) => (
                                                                        <Link to={innerSubItem.link} key={innerSubIndex}>
                                                                            <li className='space-y-1'>
                                                                                <motion.h6 className='text-black text-sm'>
                                                                                    {t(innerSubItem.title[i18n.language])}
                                                                                </motion.h6>
                                                                            </li>
                                                                        </Link>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default MobileHeader;
